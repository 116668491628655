@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes pulse {
    0%, 100% {
      transform: scale(1);
      box-shadow: 0 0 15px #0084D6;
    }
    50% {
      transform: scale(1.1);
      box-shadow: 0 0 25px #0084D6;
    }
}